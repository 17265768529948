.whitelabel {
  &-card {
    &-top {
      @extend .p-4;
      background-color: var(--si-body-bg);

      // .logo {}
    }
  }
}


.header {
  .whitelabel-logo {
    height: 47px;
  }
}

.footer {
  .whitelabel-logo {
    width: 250px;
  }
}
