.checkout {
  &-card {
    @extend .w-100;
    max-width: 640px;
    margin: 2rem 0;

    .card-header {
      i.icon {
        font-size: 3rem;
      }

      .greeting-text {
        display: block;
        font-size: 1rem;
      }
      .subtitle-text {
        display: block;
        font-weight: bold;
        font-size: 1.15rem;
        line-height: 1;
      }
    }
  }
}
