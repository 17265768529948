.inv-avatar {
  @extend .rounded-circle;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: var(--si-primary);
  color: var(--si-white);
  font-weight: bold;
  font-size: 1.5rem;

  &.md {
    width: 60px;
    height: 60px;
    font-size: 2.5rem;
  }

  &.lg {
    width: 120px;
    height: 120px;
    font-size: 3.5rem;
  }
}
