.subscription {
  &-card {
    @extend .g-col-12;
    @extend .g-col-md-6;
    @extend .card;
    @extend .overflow-hidden;

    .cover {
      @extend .ratio;
      @extend .ratio-1x1;
      @extend .bg-repeat-0;
      @extend .bg-size-cover;
      @extend .bg-position-center;
      background-color: var(--si-secondary);
    }
  }
}
